import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import SEO from "components/SEO";
import Hero from 'components/Hero';
import PostContent from 'components/_page/newsroom/PostContent';
import GetInTouch from 'components/GetInTouch';
import Related from 'components/Related';

const RenderBody = ({ post, site }) => (
  <>
    <Hero
      title={post.title && post.title[0].text}
      type={post.type && post.type.name}
      publishDate={post.publish_date || post._meta.firstPublicationDate}
      variant='condensed'
    />

    {(post.body && post.body.length > 0) && (
      <PostContent
        dek={post.dek}
        body={post.body}
        download={post.download && post.download.url}
        external={post.external_source}
      />
    )}

    <GetInTouch
      title={post.get_in_touch_heading || site.get_in_touch_heading || 'Everything starts with a conversation.'}
      signpost="View more"
    />

    {(post.related_posts && post.related_posts.length > 0 && post.related_posts[0].post) && (
      <Related
        heading='More news'
        type='post'
        cards={post.related_posts.slice(0, 3)}
      />
    )}
  </>
);

export default ({ data }) => {
  const post = data.prismic.postByUID;
  const site = data.prismic.allSite_settingss;

  if (!post) return null;

  return (
    <Layout>
      <SEO
        title={post.social_title ? post.social_title : post.title && post.title[0].text}
        description={post.social_description ? post.social_description : null}
        image={post.social_image ? post.social_image.url : null}
      />

      <RenderBody post={post} site={site} />
    </Layout>
  );
}

export const query = graphql`
  query PostQuery($uid: String!) {
    prismic {
      postByUID: post(uid: $uid, lang: "en-us") {
        _meta {
          firstPublicationDate
        }
        title
        publish_date
        type {
          ... on PRISMIC_Type {
            name
          }
        }
        dek
        body {
          ... on PRISMIC_PostBodyText {
            type
            primary {
              content
            }
          }
          ... on PRISMIC_PostBodyBlock_image {
            type
            primary {
              image
              caption
              credit
            }
          }
          ... on PRISMIC_PostBodyCustom_html {
            type
            primary {
              content
            }
          }
        }
        download {
          ... on PRISMIC__FileLink {
            _linkType
            url
          }
          ... on PRISMIC__ImageLink {
            _linkType
            url
          }
        }
        external_source
        related_posts {
          post {
            ... on PRISMIC_Post {
              _meta {
                uid
              }
              title
              type {
                ... on PRISMIC_Type {
                  name
                }
              }
              feature_image
            }
          }
        }
        get_in_touch_heading
        social_title
        social_description
        social_image
      }
      allSite_settingss {
        edges {
          node {
            get_in_touch_heading
          }
        }
      }
    }
  }
`;
