import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import Moment from 'react-moment';
import Reveal from 'react-reveal/Reveal';
import {drawIn} from 'styles/animations';

const HeroContainer = styled.div`
  background-color: #000;
  width: 100%;
  padding: 13.2rem 0 6.4rem;
  
  &.Hero--condensed {
    padding: 5.6rem 0;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-top: 15.6rem;
    
    &.Hero--condensed {
      padding-top: 5.6rem;
      padding-bottom: 8rem;
    }
  }
`;

const HeroInner = styled(Inner)`
  min-height: 24.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  
  .Hero--condensed & {
    justify-content: flex-end;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    min-height: 20.4rem;
  }
`;

const HeroTitle = styled.h2`
  position: relative;
  color: #fff;
  max-width: 107.2rem;
  padding-left: 5.4rem;
  
  &::before {
    content: "";
    display: block;
    position: absolute;
    right: calc(100% - 3.8rem);
    top: 1.6rem;
    width: 50vw;
    height: 0.4rem;
    background-color: ${colors.red600};
    transform: scaleX(0.6);
    animation-fill-mode: forwards;
  }
  
  .drawIn & {
    &::before {
      animation: ${drawIn} 0.5s cubic-bezier(0.22, 1, 0.36, 1) 0.1s forwards;
    }
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 11.2rem;
    
    &::before {
      top: 2.4rem;
      right: calc(100% - 9.6rem);
    }
  }
`;

const HeroMeta = styled.header`
  margin-bottom: 2.4rem;
  padding-left: 5.4rem;
  
  span {
    display: inline-block;
    color: #fff;
    font-size: 1.8rem;
    text-transform: lowercase;
    
    & + * {
      margin-left: 1.6rem;
    } 
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 11.2rem;
    margin-bottom: 5.6rem;
  }
`;

class Hero extends Component {
  render() {
    const { title, publishDate, type, variant, ...props } = this.props;
    return (
      <HeroContainer {...props} className={variant ? `Hero--${variant}` : ''}>
        <HeroInner>
          {(publishDate || type) && (
            <HeroMeta>
              {publishDate && (
                <span>
                  <Moment format="MM.DD.YY" date={publishDate} />
                </span>
              )}

              {type && <span>{`#${type}`}</span>}
            </HeroMeta>
          )}

          {title && (
            <Reveal effect="drawIn">
              <HeroTitle>{title}</HeroTitle>
            </Reveal>
          )}
        </HeroInner>
      </HeroContainer>
    );
  }
}

export default Hero;
