import React, { Component } from 'react';
import styled from '@emotion/styled';
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import SliceZone from 'components/SliceZone';
import Button from 'components/_ui/Button';

const ContentContainer = styled.section`
  overflow: hidden;
  margin-top: 6.4rem;
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    margin-top: 8rem;
  }
`;

const ContentWrap = styled.div`
  max-width: 84.8rem;

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-left: 11.2rem;
  }
`;

const ContentDek = styled.h4`
  position: relative;
  font-weight: 400;
  padding-bottom: 5.6rem;
  margin-bottom: 6.4rem;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: calc(100% - 5.6rem);
    width: 100%;
    height: 0.2rem;
    background-color: ${colors.red600};
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding-bottom: 8rem;
    margin-bottom: 8rem;
    
    &::after {
      width: 100vw;
      right: -11.2rem;
    } 
  }
`;

const ContentButtons = styled.div`
  margin-top: 6.4rem;
  
  > a {
    display: block;
    
    & + * {
      margin-top: 1.6rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    > a {
      display: inline-block;
      margin-right: 1.6rem;
      
      & + * {
        margin-top: 0;
      }
    }
  }
`;

class PostContent extends Component {
  render() {
    return (
      <ContentContainer>
        <Inner>
          <ContentWrap>
            {this.props.dek && <ContentDek>{this.props.dek}</ContentDek>}

            {this.props.body && (
              <SliceZone
                body={this.props.body}
                variant='post'
              />
            )}

            {(this.props.download || this.props.external) && (
              <ContentButtons>
                {this.props.download && (
                  <Button
                    as='a'
                    href={this.props.download}
                    target='_blank'
                    rel='noopener noreferrer'
                  >Download</Button>
                )}

                {this.props.external && (
                  <Button
                    as='a'
                    href={this.props.external}
                    target='_blank'
                    rel='noopener noreferrer'
                  >View Source</Button>
                )}
              </ContentButtons>
            )}
          </ContentWrap>
        </Inner>
      </ContentContainer>
    );
  }
}

export default PostContent;
